.paymentSuccess{
    display: flex;
    padding-top: 30px;
    background-color: #FFFFFF;
    justify-content: space-between;
    height: fit-content;
}

.success-right-panel {
    position: absolute;
    top: 0;
    right: 0;
    /* Position it to the right side of the screen */
    width: 19.125%;
    /* Adjust the width as needed */
    padding: 10px;
    /* border-left: 1px solid #ccc; Border on the left side */
    box-sizing: border-box;
    margin-top: 90px;
    background-color: #FFFFFF;
    margin-right: 2%;
    box-shadow: 0px 3px 12px #00000029;
    border-radius: 5px;
}