/* Add this to your CSS or styled-components */
.custom-modal {
    position: absolute;
    width: 35%;
    height: 60%;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -35%);
    background-color: white;
    padding: 20px;
    border: 1px solid #ddd;
    outline: none;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    background: none;
    border: none;
    font-size: 25px;
    color: #260F99;
  }
  
  .close-button-bottom-right {
    position: absolute;
    bottom: 10px;
    right: 10px;
    display: inline-block;
    padding: 10px, 20px;
    background-color: rgb(35,16,129); 
    color: rgb(255, 255, 255);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 130px;
    height: 38px;
    font-size: 15px;
    margin-bottom: 15px;
    margin-right: 30px;
    margin-top: 20px;
  }

.form-field {
    margin-bottom: 15px;
}

.form-field label {
    display: block;
    margin-bottom: 5px;
    color: #1C1154;
    font-size: 15px;
}

.form-field input {
    width: 90%;
    margin-top: 5px;
    min-height: 35px;
    border: 1px solid #D9D9D9;
    border-radius: 3px;
}

.deleteQuestionModal {
  position: absolute;
    width: 32%;
    top: 26%;
    left: 46%;
    transform: translate(-50%, -35%);
    background-color: white;
    padding: 20px;
    border: 1px solid #ddd;
    outline: none;
}