/* App.css */

/* @tailwind base;
@tailwind components;
@tailwind utilities; */

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');


.app {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    background-color: #F2F2F2;
  }
  
body {
  font-family: 'Poppins', sans-serif;
}



  