/* FileUploadComponent.css */

.upload-container {
    width: 100%;
    height: 200px;
    border: 1px dashed #D9D9D9;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    background-color: #F2F2F2;
    margin-bottom: 20px;
  }
  
  .upload-icon {
    font-size: 40px;
    margin-bottom: 10px;
  }
  
  .upload-text {
    margin: 0;
  }
  
  .dragging {
    background-color: #f0f0f0;
  }
  
 /* FileUploadComponent.css */

.file-list {
    margin-top: 20px;
  }
  
  .file-item {
    border-bottom: 1px solid #ccc;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .file-info {
    flex: 1;
  }
  
  .file-name {
    margin: 0; /* Remove default margin for paragraphs */
  }
  
  .upload-date {
    margin: 5px 0 0; /* Adjust top margin for date */
  }
  
  .view-content-button {
    cursor: pointer;
    background-color: #FFFFFF;
    color: #1C1154;     
    border: 1px solid #D9D9D9;
    border-radius: 3px;
    opacity: 1;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    font-weight: 500;
  }
  
  .view-content-button span {
    margin-right: 5px;
    margin-top: 2px;
  }
  
  
  