/* Content.css */
.content {
    flex: 1;
  }
  
  .content h1 {
    font-size: 24px;
    margin: 0px;
  }
  
