.column {
    flex: 1;
    border: 1px solid #ccc;
    border-left: hidden;
    border-bottom: hidden;
    border-top: hidden;
    margin-left: 30px;
    text-align: left;
    height: 65.5%;
    color: #ffffff;
    margin-top: 16px;
  }

.column p {
    margin-top: 0px;
    margin-bottom: 10px;
}