

.modal-content {
  position: fixed;
  width: 70%;
  height: 92%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  outline: none;
  border: 3px solid #D9D9D9;
}

.modal-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
}

.plans {
  display: flex;
  justify-content: space-between;
}

.plan {
  width: 30%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.plan:hover {
  background-color: #f5f5f5;
}

.pselected {
  border: 1px solid #260F99;
}

.planButton {
  font-size: 15px;
  width: 100%;
  background: #FFFFFF;
  color: #1C1154;
  height: 50px;
  border: 1px solid #D9D9D9;
  font-weight: 450;
}
.bselected {
  font-size: 15px;
  width: 100%;
  background: #1C1154;
  color: #FFFFFF ;
  height: 50px;
  border: 1px solid #D9D9D9;
  font-weight: 450;
}


.modalUl {
  list-style: none;
  padding: 0;
}


