/* LeftMenu.css */
.left-menu {
    width: 14.125%;
    color: #4D4D4D;
    background-color: #ffffff;
    margin-right: 30px;
    padding-top: 20px;
  }
  
  .left-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .left-menu li {
    cursor: pointer;
    padding: 10px;
    display: flex;
    align-items: center;
  }
  
  .left-menu li.selected {
    color: #1C1154;
    margin-right: 12px;
    width: 80%;
  }

  .icon {
    margin-right: 12px;
  }

  .indicator {
    width: 5px;
    height: 100%;
    background-color: #00aaff;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .left-menu li.selected .indicator {
    opacity: 1;
  }