.accountInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px; /* Add padding as needed */
  width: 98%;
  border-radius: 5px;
}

.accountButtons {
  display: flex;
  gap: 5px; /* Adjust the space between buttons */
}

.accountButtons button {
  cursor: pointer;
  padding: 10px 10px;
  background-color: #1c1154;
  color: #fff;
  border: 1px solid #1c1154;
  border-radius: 4px;
  font-family: "Poppins";
}

/* 425px and below: One photo per row */
@media screen and (max-width: 768px) {
  .account-info-container {
    width: 95% !important;
    border-radius: "5px";
  }
}

/* 425px and below: One photo per row */
@media screen and (max-width: 425px) {
}
