/* GalleryTab.css */

.gallery-container {
  width: 95%;
    margin: 0 auto;
  }
  
  .image-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }
  
  .image-item {
    flex: 1 1 calc(25% - 16px); /* Four items per row for wide screens */
    margin-bottom: 16px;
  }
  
  .img-fluid {
    width: 100%;
    height: auto;
  }
  
  .pagination {
    display: flex;
    gap: 8px;
    justify-content: center;
    margin-top: 16px;
  }
  
  .page-item {
    cursor: pointer;
    padding: 8px 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .page-item.active {
    background-color: #260F99;
    color: #fff;
  }

  .download-button-container {
    position: absolute;
    left: 0;
    bottom: 0;
    margin-bottom: 16px; /* Adjust margin as needed */
    font-family: 'Poppins';
  }

  /* 1600px and below: Three photos per row */
@media screen and (max-width: 1600px) {
  .image-item {
      flex: 1 1 calc(33.33% - 16px); /* Adjust width for three items per row */
  }
}

/* 1280px and below: Two photos per row */
@media screen and (max-width: 1280px) {
  .image-item {
      flex: 1 1 calc(50% - 16px); /* Adjust width for two items per row */
  }
}

/* 425px and below: One photo per row */
@media screen and (max-width: 425px) {
  .image-item {
      flex: 1 1 100%; /* Full width for one item per row */
  }
}
  