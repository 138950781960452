/* YourComponent.css */

.bordered-box {
    border: 1px solid #D9D9D9;
    display: flex;
    align-items: center;
    padding-left: 30px; /* Add padding as needed */
    padding-top: 15px;
    padding-bottom: 15px;
  }
  
  .image {
    max-width: 100px; /* Set a maximum width for the image */
    margin-right: 15px; /* Add space between image and text */
    height: 80px;
  }
  
  .invoiceContent1 {
    flex-grow: 1;
    background-color: #fff; /* Allow content to take remaining space */
    padding-top: 18px;
  }
  
  .rightmost-content {
    margin-left: auto; /* Move this content to the rightmost side */
    padding-right: 30px;
    color: #4D4D4D ;
  }

  .invoiceContent{
    padding: 30px;
    border: 1px solid #D9D9D9;
    border-top: hidden;
  }
  
  /* Additional styling for text content if needed */

  /* Dropdown.css */

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  /* Adjusted styles for vertical list */
  width: 160px;
  border-radius: 4px;
  margin-bottom: 15px;
}

.dropdown.open .dropdown-content {
  display: block;
}

.dropdown-text {
  padding: 10px;
  cursor: pointer;
}

.arrow-down {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}  


.events-button-hover-underline {
  text-decoration: none; /* No underline by default */
  transition: text-decoration 0.3s ease; /* Smooth transition */
}

.events-button-hover-underline:hover {
  text-decoration: underline;
}

.customQuestion-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #d9d9d9;
}

.customQuestion-modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  text-align: center;
}

.customQuestion-modal-content button {
  margin-top: 10px;
  padding: 8px 15px;
  background: white;
  border: none;
  color: #595959;
  border-radius: 4px;
  cursor: pointer;
}

.invoiceClientTab {
  width: 100% !important;
}

