/* Header.css */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
  }
  
  .logoImage {
    display: contents !important;
    width: auto;
  }

  .logo-container {
    width: 150px; /* Adjust the size to your preference */
    height: 50px; /* Fixed height for the logo container */
    overflow: hidden; /* Hide overflow if the image aspect ratio differs */
}

.logo-container img {
    width: 100%;
    height: 100%;
    object-fit: contain; /* Ensures the image fits while maintaining aspect ratio */
}
  
  .search-bar input {
    padding: 5px;
    border: 1px solid;
    border-radius: 4px;
    width: 21rem;
    height: 26px;
    margin-top: 3px;
    margin-left: -13rem;
    color: rgb(198, 194, 194);;
  }

  .createButtonStyle {
    display: inline-block;
    padding: 10px, 20px;
    background-color: rgb(35,16,129); 
    color: rgb(255, 255, 255);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 130px;
    height: 38px;
    font-size: 15px;
  }
  
  .notification {
    cursor: pointer;
    color: rgb(28, 17, 83);
    width: 3rem;
  }
  
  .bell-icon {
    font-size: 20px;
  }
  
  .profile {
    position: relative;
    cursor: pointer;
    width: 35px; /* Example width, adjust as needed */
    height: 35px; 
    
  }
  
  .profile img {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the entire circle */
    
  }
  
  .profile-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1;
  }

  .createButton-dropdown {
    position: absolute;
    top: 100%;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1;
    width: 54%;
    height: 35px;
    display: flex;
  }

  /* .createButton-dropdown ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .createButton-dropdown li {
    padding: 10px;
    cursor: pointer;
  }
  
  .createButton-dropdown li:hover {
    background-color: #f0f0f0;
  } */
  
  .profile-dropdown ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .profile-dropdown li {
    padding: 10px;
    cursor: pointer;
  }
  
  .profile-dropdown li:hover {
    background-color: #f0f0f0;
  }

.createNewBookingStyle {
  width: 100%;
  background-color: #FFFFFF;
  color: #1C1154;
}

.searchButton-dropdown {
  position: absolute;
  top: 6.20%;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1;
  width: 6.7%;
  height: 35px;
  display: flex;
  margin-left: 10.4%;
}

.notification-layover {
  position: absolute;
  top: 59px; /* Slightly below the bell icon */
  right: 4%;
  background: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  max-height: 350px; /* Set a max height to allow scrolling */
  overflow-y: auto;
  width: 23%; /* Set a consistent width */
  padding: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15); /* Shadow for depth effect */
  border-radius: 0px 0px 3px 3px; /* Rounded bottom corners */
  opacity: 1;
}

.notification-title {
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  color: #000000;
  margin-bottom: 10px;
  position: relative;
}

/* .notification-title::after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: #eee;
  margin-top: 10px;
} */

.notification-item {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.profile-dropdown-item {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 5%;
  padding-right: 0px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.notification-item:hover {
  background: #f7f7f7; /* Light gray background */
}

.notification-item h4 {
  font-size: 16px;
  color: #080700; /* Dark purple */
  margin: 0;
  transition: color 0.2s ease, text-decoration 0.2s ease;
}

.notification-item:hover h4 {
  color: #2A1980; /* Blue color on hover */
  text-decoration: underline;
}

.notification-item p {
  font-size: 14px;
  color: #555; /* Gray for readability */
  margin: 0;
}

.timestamp {
  font-size: 12px;
  color: #AAA; /* Light gray for timestamps */
  margin-top: 5px;
}

.no-notifications {
  text-align: center;
  font-size: 14px;
  color: #888;
  padding: 20px;
}
.clientBoothLogo {
  width: 15% ;
}

@media screen and (max-width: 768px) {
  .clientBoothLogo {
    width: 30% !important ;
  }
}







  