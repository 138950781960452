.right-panel {
    position: absolute;
    top: 0;
    right: 0;
    /* Position it to the right side of the screen */
    width: 19.125%;
    /* Adjust the width as needed */
    padding: 10px;
    /* border-left: 1px solid #ccc; Border on the left side */
    box-sizing: border-box;
    margin-top: 90px;
    background-color: #FFFFFF;
    margin-right: 2%;
    border-radius: 5px;
    box-shadow: 0px 2px 4px #15223214;
}

.entry {
    padding: 8px;
    border-bottom: 1px solid #ddd;
    /* Border between entries */
}

.entry:last-child {
    border-bottom: none;
    /* Remove the border for the last entry */
}

.right-panel-content {
    border: 1px solid #ddd;
    margin-top: 0px;
    padding-left: 15px;
    padding-top: 15px;
    font-weight: 500;
    color: #4D4D4D;
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    border-radius: 5px;
}

.right-panel-content-icon {
    align-self: flex-end;
    color: #1C1154;
    font-size: 22px;
}

.right-panel-arrow-container {
    display: flex;
    justify-content: space-between;
    padding-top: 3px;
    padding-bottom: 2px;
    border-radius: 5px;
  }

  .createButtonStyleRP {
    background-color: rgb(35,16,129); 
    color: rgb(255, 255, 255);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    height: 38px;
    font-size: 15px;
    width: 99%;
    font-family: 'Poppins';
  }

  .members-panel{
    position: absolute;
    right: 0;
    /* Position it to the right side of the screen */
    width: 100%;
    /* Adjust the width as needed */
    padding-top: 18px;
    padding-left: 3%;
    /* border-left: 1px solid #ccc; Border on the left side */
    box-sizing: border-box;
    margin-top: 30px;
    background-color: #FFFFFF;
    border-radius: 5px;
    box-shadow: 0px 2px 4px #15223214;
  }

  .addTeamMemberButton {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(35,16,129); 
    color: rgb(255, 255, 255);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 96%;
    height: 38px;
    font-size: 15px;
    margin-bottom: 2%;
    margin-right: 3%;
    font-family: 'Poppins';
  }

  .events-button-hover-underline {
    text-decoration: none; /* No underline by default */
    transition: text-decoration 0.3s ease; /* Smooth transition */
  }

  .events-button-hover-underline:hover {
    text-decoration: underline;
  }

