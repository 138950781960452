.clientProfileDetails {
    width: 25%;
    color: #4D4D4D;
    background-color: #ffffff;
    margin-left: 1%;
    margin-top: 20px;
    margin-bottom: 20px;
    border: solid 1px #d4d4d4;
  }



  .clientLandingTabs{
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 1%;
    width: calc(100% - 2%);
    background-color: #ffffff;
    padding-left: 20px;
    padding-top: 0px !important;
  }

  .cancel-reschedule-btn {
    display: none;
  }

  .overviewClientContainer {
    display: flex;
    width: 100%; /* Full width of the container */
  }

  .templateClientContainer {
    display: flex;
    width: 100% !important; /* Full width of the container */
  }

  .invoiceClientContainer {
    display: flex;
    width: 100%; /* Full width of the container */
  }
  
  .overviewClientLeftPanel {
    flex: 2; /* Takes 2 parts of the space */
    width: 66%; /* Fallback for older browsers */
  }
  
  
  
  /* Right panel - 33% width */
  .overviewClientRightPanel {
    flex: 1; /* Proportionally takes 1 part out of 3 */
    width: 33%; /* Fallback for older browsers */
  }

  .invoiceClientRightPanel {
    flex: 1; /* Takes 1 part of the space */
    width: 25% !important; /* Fallback for older browsers */
  }
  
  /* Left panel - 66% width */
  .templateClientLeftPanel {
    flex: 3; /* Proportionally takes 2 parts out of 3 */
    width:66% !important; /* Fallback for older browsers */
  }

  .templateClientRightPanel {
    flex: 1; /* Takes 1 part of the space */
    width: 25% !important; /* Fallback for older browsers */
    padding-top: 0px !important;
    padding-right: 5%;
    padding-left: 2%;
  }

  .templateClientRightPanel .right-panel-content {
    margin-top: 0px !important;
  }
  
  /* Left panel - 66% width */
  .invoiceClientLeftPanel {
    flex: 3; /* Proportionally takes 2 parts out of 3 */
    width:75% !important; /* Fallback for older browsers */
  }

  .overviewClientRightPanel .right-panel-content {
    margin-top: 0px !important;
  }
  
  .clientLandingTabs .react-tabs__tab {
    padding-left: 0px;
    margin-right: 25px;
  }
  
  .clientLandingTabs .react-tabs__tab--selected {
    color: black;
    border: 1px solid transparent;
    border-radius: 5px 5px 0 0;
    border-bottom: 2px solid black;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
  }
  
  .clientLandingTabs .react-tabs__tab:focus:after {
    content: none;
  }

  .clientLandingTabs .react-tabs__tab:hover {
    color: inherit; /* This will be controlled by inline styles */
}

.client-container {
  background-color: #f2f2f2;
}

.main-content {
  display: flex;
  flex-direction: row;
}

.invoiceClientTabContainer {
  width: 100% !important;
}

.clientLandingTabs .client-events-table {
  width: 97%;
  padding-left: 15px;
  padding-bottom: 0px;
}
.clientProfileContainer .clientProfileImage {
  padding-left: 5%;
  width: 100px;
}

.overview-event-name {
  display: none !important;
  color: #000000;
}



/* Responsive Styles */

/* iPad (768px and below) */
@media screen and (max-width: 768px) {
  .main-content {
    flex-direction: column; /* Stack elements vertically */
  }

  .clientLandingTabs .react-tabs__tab {
    padding-left: 0px;
    margin-right: 8px;
  }

  .clientLandingTabs .react-tabs__tab-list {
    padding-left: 13%;
  }

  .clientLandingPageTabs .react-tabs__tab-list {
    padding-left: 2.5%;
  }

  .clientLandingPageTabs .react-tabs__tab {
    padding-right: 3%;
    width: 15%;
    padding-left: 7%;
  }

  .clientLandingPageTabs .client-events-table{
    border: none !important;
}

  .overview-event-name {
    display: inline-block !important;
  }

  .overview-members-container {
    border: none !important;
    padding-top: 30px !important;
    padding-left: 0% !important;
  }

  .overview-team-members {
    display: flex;
    justify-content: space-between; /* Align items at the ends of the row */
    align-items: center; /* Center items vertically */
    flex-wrap: wrap; /* Allow wrapping to the next row if needed */
    gap: 20px; /* Add spacing between rows or items */
  }
  
  .overview-team-members > div {
    flex: 1 1 calc(50% - 20px); /* Take 50% width minus gap */
    max-width: calc(100% - 20px); /* Prevent overflowing */
    box-sizing: border-box; /* Ensure padding/border is included in width */
  }

  .overviewClientContainer {
    flex-direction: column; /* Stack elements vertically */
  }

  .invoiceClientContainer {
    flex-direction: column;
  }

  .cancel-btn-overview {
    display: none;
    
  }

  .cancel-reschedule-btn {
    display: inline-block; /* Make it visible */
    width: 43% !important;
    margin-left: 3%;
  }

  .events-button-hover-underline {
    margin-left: 1.5%;
    width: 43% !important;
  }

  .overviewClientRightPanel {
    order: 1; /* Show this first */
    width: 75%; /* Full width */
    display: flex;
    flex-direction: column;
    align-content: space-between;
    padding-left: 14.5%;
  }

  .overviewClientRightPanel .right-panel-content {
    border: none !important;
    padding-left: 0px;
  }

  .package-addOn-div {
    display: flex;
    align-content: space-between !important;
  }

  .overview-package {
    width: 45% !important;
    margin-right: 3%;
  }

  .package-addOn-div p {
    margin-top: 5px;
  }

  

  .overview-addOns {
    width: 30%;
    margin-left: 20%;
  }

  

  .overviewClientLeftPanel {
    order: 2; /* Show this second */
    width: 72%; /* Full width */
    margin-left: 0px !important;
    padding-left: 14%;
  }

  .invoiceClientRightPanel {
    order: 2; /* Show this second */
    width: 72% !important; /* Full width */
    margin-left: 0px !important;
    padding-left: 14%;
  }

  .invoiceClientLeftPanel {
    order: 1; /* Show this first */
    width: 75%; /* Full width */
    display: flex;
    flex-direction: column;
    align-content: space-between;
    padding-left: 14.5%;
  }

  .templateClientContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  .templateClientRightPanel {
    order: 1; /* Show this second */
    width: 75% !important; /* Full width */
    margin-left: 0px !important;
    padding-left: 14%;
  }

  .templateClientRightPanel .events-button-hover-underline {
    width: 100% !important;
    margin-left: 0px !important;
  }

  .client-template-cover-image {
    display: none;
  }

  .templateClientLeftPanel {
    order: 2; /* Show this first */
    width: 75.5% !important; /* Full width */
    display: flex;
    flex-direction: column;
    align-content: space-between;
    padding-left: 13.7%;
  }

  .templateClientLeftPanel .invoiceContent {
   padding: 0px !important;
  }
  .gallery-container {
    width: 75% !important;
    padding-left: 5%;
  }

  .invoiceClientLeftPanel .invoiceClientTab .bordered-box {
    border-left: none !important;
    border-top: none !important;
    border-right: none !important;
  }

  .invoiceClientRightPanel .right-panel-content {
    order: 2; /* Show this second */
    width: 100% !important; /* Full width */
    margin-left: 0px !important;
    padding-left: 2.5% !important;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    border-left: none;
    border-right: none;
  }

  .invoiceClientRightPanel .events-button-hover-underline {
    margin-left: 0px !important;
    padding-left: 0px !important;
  }

  .packages-client-tab {
    padding-left: 12%;
    width: 78% !important;
    border: none !important;
  }

  .packages-client-tab .invoiceContent {
    border: none;
    padding: 0px !important;
  }

  .client-questionnaire-buttons {
    display: none !important;
  }

  .client-questionnaire-container {
    width: 70% !important;
    padding-left: 14% !important;
  }

  .client-questionnaire-cover-pic {
    display: none;
  }

  /* .packages-client-tab .invoiceContent .right-panel-content {
    
  } */

  .packages-client-tab .package-tab-cover-pic {
    display: none;
  }

 

  .invoiceClientLeftPanel .invoiceClientTab .invoiceContent {
    border-left: none !important;
    border-top: none !important;
    border-right: none !important;
    border-bottom: none !important;
  }

  .overviewClientLeftPanel .event-overview-div {
    margin-left: 0px !important;
    padding-left: 0px !important;
  }

  .overviewClientLeftPanelImage {
    display: none;
  }

  .clientDataPanelButtons{
    /* display: flex ; */
  }

  

  .clientProfileDetails {
    width: 100%;
    margin-left: 0%;
    margin-bottom: 0px;
    margin-top: 0px;
    border-left: none;
    border-right: none;
  }

  .clientLandingTabs {
    width: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-left: 0%;
  }

  .clientLandingTabs .client-events-table {
    width: 101%;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: -20px;
    padding-left: 3%  !important;
    border-style: none !important; 
  }

  .clientDataPanel {
    font-weight: 500;
    color: var(--primary-color); /* Replace this with your CSS variable or specific color value */
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two equal columns */
    gap: 3px; /* Adds spacing between grid items */
    padding-left: 2%;
  }
  .clientProfileContainer  {
    width: 80%;
    padding-left: 9%;
  }
  .clientProfileContainer h4  {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .clientProfileContainer p  {
    margin-top: 10px;
  }

  .clientProfileContainer h2  {
    padding-left: 1.3%;
    margin-top: 15px;
  }

  .clientProfileContainer .clientProfileImage {
    padding-left: 5%;
    width: 100px;
  }

  .event-total-div {
    display: flex;
    justify-content: space-between;
  }

  .event-total-box {
    width: 34%;
  }

  .event-total-box h1 {
    font-size: 24px;
  }

  .event-total-box h1 {
    font-size: 24px;
    
  }

  .client-questionnaire-container .right-panel {
    display: none !important;
  }
}
/* Mobile Phones (480px and below) */
@media screen and (max-width: 425px) {
  /* .clientLandingTabs{
    width: 10%;
    background-color: #ffffff;
    padding-top: 0px !important;
  } */

  .clientProfileContainer  {
    padding-left: 0%;
    padding-right: 1%;
    width: 100% !important;
  }

  .client-questionnaire-container .right-panel {
    display: none !important;
  }

  .client-questionnaire-buttons {
    display: none !important;
  }

  .clientProfileContainer .clientProfileImage {
    padding-left: 1%;
    width: 100px;
  }

  .clientProfileContainer h2  {
    padding-left: 0px;
    margin-left: 0% !important;
  }

  .clientDataPanel {
    margin-left: 0px !important;
    margin-right: 0px !important;
    font-weight: 500;
    color: var(--primary-color); /* Replace this with your CSS variable or specific color value */
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two columns */
    grid-template-rows: auto auto; /* Two rows for the first two items */
    gap: 8px !important; /* Adds spacing between grid items */
    padding-left: 0%;
}

/* Styling for items */
.clientDataPanel > :nth-child(1),
.clientDataPanel > :nth-child(2) {
    grid-column: span 2; /* These span across two columns */
}

/* Make tabs occupy 50% width */
.clientLandingPageTabs .react-tabs__tab {
  width: 50%; /* Each tab takes 50% of the width */
  text-align: center; /* Center align text */
  box-sizing: border-box;
}

.clientLandingPageTabs .react-tabs__tab-list {
  display: flex; /* Flex container for the tabs */
  justify-content: space-between; /* Equal space between tabs */
  padding: 0;
}

/* Make the event table scrollable */
.clientLandingTabs .client-events-table {
  width: auto; /* Allow the table to overflow */
  display: block; /* Ensures block-level rendering */
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent text wrapping inside the table */
  border-collapse: collapse; /* Optional: Keeps table style consistent */
}

.clientLandingTabs .client-events-table th,
.clientLandingTabs .client-events-table td {
  white-space: nowrap; /* Prevent wrapping of cell content */
  padding-left: 8px; /* Add space before content */
  padding-right: 8px; /* Optional: Add space after content */
  text-align: left; 
}

.clientLandingTabs .client-events-table th {
  background-color: #f9f9f9; /* Optional: Highlight headers */
  border-bottom: 2px solid #d4d4d4; /* Optional: Add border to separate header */
}

/* Ensure the parent container doesn't clip the table */
.clientLandingTabs {
  overflow: hidden; /* Prevent clipping of table content */
}

.clientDataPanelButtons {
  display: flex;
  flex-direction: column; /* Stack buttons vertically */
    align-items: center; /* Optional: Center buttons horizontally */
    gap: 15px; /* Space between buttons */
    width: 100%;
    margin-left: 0px !important;
}

.events-button-hover-underline {
  margin-left: 0% !important;
  padding-left: 0% !important;
  width: 99% !important;
}

.cancel-reschedule-btn {
  width: 99% !important;
  margin-left: 0% !important;
  padding-left: 0% !important;
}

.clientLandingTabs .react-tabs__tab-list {
  display: flex; /* Ensure tabs are in a row */
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent wrapping */
  margin-bottom: 0; /* Adjust as needed */
  padding: 0 10px; /* Add padding for better spacing */
  scrollbar-width: thin; /* Thin scrollbar for supported browsers */
}

.clientLandingTabs .clientLandingPageTabs .react-tabs__tab-list {
  overflow-x: clip; /* Enable horizontal scrolling */
  white-space: wrap; /* Prevent wrapping */
  margin-bottom: 0; /* Adjust as needed */
  padding: 0 10px; /* Add padding for better spacing */
  scrollbar-width: thin; /* Thin scrollbar for supported browsers */
}

/* Optional: Custom Scrollbar Styling */
.clientLandingTabs .react-tabs__tab-list::-webkit-scrollbar {
  height: 5px; /* Height of the scrollbar */
}

.clientLandingTabs .react-tabs__tab-list::-webkit-scrollbar-thumb {
  background: #ccc; /* Color of the scrollbar */
  border-radius: 5px; /* Rounded edges */
}

.clientLandingTabs .react-tabs__tab-list::-webkit-scrollbar-track {
  background: #f9f9f9; /* Track background */
}

/* Tab Items */
.clientLandingTabs .react-tabs__tab {
  flex: 0 0 auto; /* Prevent shrinking or stretching of tabs */
  margin-right: 10px; /* Spacing between tabs */
  padding: 10px 15px; /* Adjust padding for better click area */
  text-align: center;
}

.clientLandingTabs .react-tabs__tab-list {
  padding: 0 5px; /* Adjust padding for smaller screens */
}

.clientLandingTabs .react-tabs__tab {
  font-size: 14px; /* Reduce font size for smaller screens */
  padding: 8px 10px; /* Adjust padding for smaller buttons */
}

.overviewClientRightPanel {
  width: 100% !important;
  padding-left: 5px !important;
}

.event-total-div {
  flex-direction: column !important;
  gap: 10px;
}

.event-total-box {
  width: 70%;
}

.package-addOn-div {
  flex-direction: column !important;
}

.overview-package {
  width: 70% !important;
}

.overview-addOns {
  width: 70%;
  margin-left: 0px;
}

.overview-team-members {
  display: block !important;
}


.overviewClientLeftPanel {
  width: 97% !important;
  padding-left: 5px;
}

.invoiceClientLeftPanel {
  width: 99% !important;
  padding-left: 0px !important;
  margin-left: 0px !important;
}

.invoiceClientRightPanel {
  width: 99% !important;
  padding-left: 0px !important;
  margin-left: 0px !important;
}

.invoiceClientLeftPanel .invoiceClientTab .bordered-box {
  padding-left: 5px !important;
}

.invoiceClientLeftPanel .invoiceClientTab .invoiceContent .right-panel-content {
  padding-left: 10px !important;
  padding-right: 10px !important;
  width: 100% ;
}

.invoiceClientRightPanel .invoice-download-button-mobile {
  width: 97% !important;
  padding-left: 2% !important;
}

.invoiceClientLeftPanel .invoiceClientTab .invoiceContent {
  padding-left: 5px;
}

.invoiceClientLeftPanel .invoiceClientTab .bordered-box .invoice-client-mobile {
  display: flex;
}

.invoice-logo-vendor {
  display: block;
  padding-top: 18px;
}

h1 {
  font-size: 20px; /* Adjust to desired size */
  line-height: 1.2; /* Optional: Improve readability */
}

h2 {
  font-size: 18px; /* Adjust size */
  line-height: 1.2; /* Optional: Improve readability */
}

h3 {
  font-size: 16px; /* Adjust size */
  line-height: 1.2;
}

h4 {
  font-size: 14px; /* Adjust size */
  line-height: 1.2;
}

h5 {
  font-size: 12px; /* Adjust size */
  line-height: 1.2;
}

h6 {
  font-size: 10px; /* Adjust size */
  line-height: 1.2;
}

.packages-client-tab {
  width: 99% !important;
  padding-left: 1% !important;
}

.packages-client-tab .invoiceContent .right-panel-content {
  padding: 10px !important;
  margin-top: 10px !important;
}

.packages-client-tab .invoiceContent .bordered-box {
  padding-left: 10px !important;
}

.payment-schedule-client-packages {
  padding: 1.3% !important;
}

.payment-schedule-client-packages .payment-schedule-client-packages-table {
  padding: 1% !important;
}

.client-questionnaire-container {
  width: 98% !important;
  padding-left: 1% !important;
}

.templateClientLeftPanel {
  width: 98% !important;
  padding-left: 1% !important;
}

.templateClientRightPanel {
  width: 98% !important;
  padding-left: 1% !important;
  margin-top: 10px !important;
}

.templateClientRightPanel .right-panel-content {
  padding-top: 0px !important;
}

.gallery-container {
  width: 98% !important;
  margin-top: 10px !important;
  padding-left: 0% !important;
}

}