.right-donut{
    position: absolute;
    right: 0;
    background-color: #ffffff;
    margin-top: 200px;
    width: 19%;
    margin-right: 2%;
    height: 355px;
}

.lead-donut{
    position: absolute;
    right: 0;
    margin-top: 580px;
    background-color: #ffffff;
    margin-right: 2%;
    width: 19%;
    height: 385px;
}

.donut{
    padding: 15%;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    margin-top: 30px;
}

.donut-lead{
    padding: 15%;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    margin-top: 30px;
}


    
.donutchart .donutchart-arcs path {
    transition: none !important; /* Disable any transition effects */
}

.donutchart .donutchart-arcs path:hover {
   color: #260F99 !important;
   fill: none !important;
    transform: none !important; /* Prevent size change on hover */
}

.donutchart .donutchart-arcs path:nth-child(2):hover {
    fill: #B5A6FF !important;
    color: none !important;
    transform: none !important;
}

.donutchart .donutchart-arcs path:nth-child(3):hover {
    fill: #D9D9D9 !important; 
    color: none !important; 
    transform: none !important;
}

.custom-legend {
    display: flex;
    flex-direction: column;
    padding: 10px 22px;
    margin-top: 15px;
  }
  
  .legend-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
    padding-top: 10px;
  }
  
  .color-box {
    width: 12px;
    height: 12px;
    margin-right: 10px;
  }
  
  .legend-label {
    flex-grow: 1;
    text-align: left;
    padding-left: 10px;
    font-size: 14px;
    color: #1C1154;
  }
  
  .legend-value {
    text-align: right;
    font-size: 14px;
    color: #1C1154;
  }
  