.right-panel-analytics {
    position: absolute;
    top: 0;
    right: 0;
    /* Position it to the right side of the screen */
    width: 19%;
    /* Adjust the width as needed */
    padding: 10px;
    /* border-left: 1px solid #ccc; Border on the left side */
    box-sizing: border-box;
    margin-top: 145px;
    background-color: #FFFFFF;
    margin-right: 2%;
    height: 270px;
}

.right-panel-content-analytics {
    border-bottom: 1px solid #ddd;
    margin-top: 0px;
    padding-left: 15px;
    font-weight: 500;
    color: #4D4D4D;
    display: flex;
    flex-direction: column;
    margin-top: 15px;
}