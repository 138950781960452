.events-add-button{
    width: 7%;
    height: 35px;
    border: 1px solid #D9D9D9;
    border-radius: 3px;
    opacity: 1;
    background-color: #FFFFFF;
    color: #1C1154;
    font-size: 14px;
    right: 30px;
    position: absolute;
    box-shadow: 0px 2px 4px #15223214;
}

.events-table{
    width: 95.45%;
    background-color: #ffffff;
    padding-left: 20px;
    margin-bottom: 20px;
    margin-left: 30px;
    border: 1px solid #D9D9D9;
    padding-bottom: 40px;
    box-shadow: 0px 2px 4px #15223214;
    border-radius: 5px;
}
