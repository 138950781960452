

  .backToAcButton {
    display: inline-block;
    padding: 10px, 20px;
    background: linear-gradient(to right, #1C1154, #260F99 ); 
    color: rgb(255, 255, 255);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 65%;
    height: 27px;
    font-size: 12px;
  }

  .react-tabs__tab{
    padding-left: 0px;
    margin-right: 25px;
  }

  .react-tabs__tab--selected {
    color: #1C1154;
    border: 1px solid transparent;
    border-radius: 5px 5px 0 0;
    border-bottom: 2px solid #260F99;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
  }

.react-tabs__tab:focus:after{
content:none
}
  

  
  
  
  
  
  
  