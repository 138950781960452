.customDashboardTable{
    width: 72%;
    background-color: #ffffff;
    margin-top: 30px;
    padding-left: 20px;
    padding-bottom: 40px;
    margin-bottom: 20px;
    border-radius: 5px;
    box-shadow: 0px 2px 4px #15223214;
}

.custom-table-row{
    height: 46px;
    font-size: 15px;
    font-weight: 150;
}

.custom-header{
    font-size: 15px;
    font-weight: 200;
    padding-left: 0px;
}

.table-header-icon{
    font-size: 10px !important;
}

.table-search-bar{

}

.rc-table-footer{
    margin-top: 15px;
    height: 30px;
}

.tableDownloadButton {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(35,16,129); 
    color: rgb(255, 255, 255);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 15%;
    height: 38px;
    font-size: 15px;
    margin-bottom: 2%;
    margin-right: 3%;
    font-family: 'Poppins';
  }

.odd-row {
    background-color: #f9f9f9; /* Optional: Add a background color for alternating rows */
  }
  
  .odd-row td {
    border-bottom: 1px solid #ddd; /* Add a bottom border to odd rows */
  }

  .table-row-with-border td {
    border-bottom: 1px solid #D9D9D9; /* Add a black bottom border to every row */
  }