.login {
  position: absolute;
  top: 35%; /* Increased to move the login panel lower */
  left: 50%;
  transform: translate(-50%, -50%);
  width: 38%;
  height: 458px;
  background: #FFFFFF;
  box-shadow: 0px 3px 12px #00000029;
  border-radius: 5px;
  opacity: 1;
}



.login-input {
        border: 1px solid #D9D9D9;
        border-radius: 5px;
        font-size: 15px;
        width: 100%;
        height: 50px;
        margin-top: 10px;
        padding: 0 10px; /* Adjust padding as needed */
      }
      
      .login-button {
        margin-top: 35px;
        height: 40px;
        width: 100%;
        background: #260F99;
        border: 1px solid #43425D;
        color: #FFFFFF;
        font-size: 17px;
        font-weight: 450;
        border-radius: 5px;
      }
      
      .login-button:hover {
        background: #1C1154; /* Change background color on hover */
        cursor: pointer;
      }

.verify-email {
  position: absolute;
  top: 32%; /* Increased to move the login panel lower */
  left: 50%;
  transform: translate(-50%, -50%);
  width: 39%;
  height: 258px;
  background: #FFFFFF;
  box-shadow: 0px 3px 12px #00000029;
  border-radius: 5px;
  opacity: 1;
}
      